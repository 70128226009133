// Helpers
import {
  to,
  cloneObject,
  stripDashes,
  convertStringToBoolean,
  shallowCompare,
  formatAddressFromForm,
  shouldVerifyAddress,
} from '../../../lib/helpers';
import {
  checkRequestFailure,
  handleRequestFailure,
} from '../../../lib/validation';

// Vendors
import { push } from 'connected-react-router';

// Requests
import {
  submitTDLRLRRenewalRequest,
  userVerifyAddressRequest,
  lookupAppLinkRequest,
} from '../../../backend/requests';

// Selectors
import { selectLicense, selectAppProfile } from './tdlrSelectors';

// Actions
import {
  APP_TRANSACTION_IDS,
  storeBasicInfo,
  clearAppData,
} from '../../app/appActions';
import { openModal } from '../../modal/modalActions';

// Consts
export const TDLR_TDLRLR_CLEAR_DATA = '[TDLR] TDLRLR - Clear Data';
export const TDLR_RENEW_LICENSE = '[TDLR] Renew License';
export const TDLR_STORE_APP_PROFILE = '[TDLR] Store App Profile';
export const TDLR_STORE_FULL_PROFILE = '[TDLR] Store Full Profile';
export const DMV_MELISA_SUCCESS = 'SUCCESS';
export const TDLR_CONTINUE = 'CONTINUE';
export const TDLR_WARNING = 'WARNING';
export const TDLR_ERROR = 'ERROR';
export const TDLR_ADDRESS_TWO_MISSING = 'ADDRESS_TWO_MISSING';
export const TDLR_ADDRESS_TWO_INVALID = 'ADDRESS_TWO_INVALID';
export const TDLR_ADDRESS_NOT_VERIFIED = 'ADDRESS_NOT_VERIFIED';
export const TDLR_INVALID_ADDRESS = 'INVALID_ADDRESS';
export const TDLR_UPDATE_PAYMENT_WAS_MADE = 'FALSE';

export function renewTDLRLicense(data) {
  return async function(dispatch) {
    dispatch({
      type: TDLR_RENEW_LICENSE,
      payload: data,
    });
    await dispatch(getAppProfileByTDLRLicense());
    dispatch(push('/tdlrlr'));
  };
}

export const updatePaymentWasMade = (status = false) => {
  return {
    type: TDLR_UPDATE_PAYMENT_WAS_MADE,
    status: status,
  };
};

export function storeAppProfile(payload) {
  return {
    type: TDLR_STORE_APP_PROFILE,
    payload,
  };
}

export function getAppProfileByTDLRLicense(
  transactionId = APP_TRANSACTION_IDS.RENEWAL_APP_LOOKUP
) {
  return async function(dispatch, getStore) {
    const store = getStore();
    const license = store.tdlr.selected;
    const {
      licenseData: { renewalApplicationId },
      userKey,
      appId,
    } = license;

    const requestBody = {
      appId,
      userKeys: [userKey],
      transactionId,
      additionalParams: [],
    };

    if (transactionId === APP_TRANSACTION_IDS.RENEWAL_APP_LOOKUP)
      requestBody.additionalParams.push({
        name: 'renewalApplicationId',
        value: renewalApplicationId,
      });

    const [{ body }] = await to(dispatch(lookupAppLinkRequest(requestBody)));
    dispatch(storeAppProfile(body.data));
  };
}

export const updateTDLRLRFullProfile = ({ values, formikActions }) => async (
  dispatch,
  getState
) => {
  const fullProfile = selectAppProfile(getState());
  const formValues = cloneObject(values);

  formValues.address = formatAddressFromForm(formValues.address);

  if (shouldVerifyAddress(formValues)) {
    const [{ body, response }] = await to(
      dispatch(userVerifyAddressRequest(formValues.address))
    );

    if (checkRequestFailure({ body, response })) {
      handleRequestFailure(body, formikActions, {
        'address.streetAddress': 'streetAddress',
        'address.addressLine2': 'addressLine2',
        'address.city': 'city',
        'address.state.value': 'state',
        'address.zipFive': 'zipFive',
      });

      return Promise.reject();
    }

    formikActions.setSubmitting(false);

    if (body.status === 'CONTINUE') {
      dispatch(
        openModal('VerifyAddressModal/TDLRLR', {
          enteredAddress: formValues.address,
          addressOptions: body.data,
        })
      );
      return Promise.resolve();
    }

    if (body.status === TDLR_WARNING || body.status === TDLR_ERROR) {
      switch (body.message) {
        case TDLR_ADDRESS_TWO_MISSING:
          dispatch(
            openModal('AddressTwoMissingModal/TDLRLR', {
              enteredAddress: formValues.address,
              addressOptions: body.data,
            })
          );
          break;
        case TDLR_ADDRESS_TWO_INVALID:
          dispatch(
            openModal('AddressTwoIncorrectModal/TDLRLR', {
              enteredAddress: formValues.address,
              addressOptions: body.data,
            })
          );
          break;
        case TDLR_ADDRESS_NOT_VERIFIED:
        case TDLR_INVALID_ADDRESS:
          dispatch(
            openModal('AddressCantConfirm/TDLRLR', {
              enteredAddress: formValues.address,
            })
          );
          break;
        default:
          break;
      }
      return Promise.resolve();
    }
  }

  if (formValues.contactInfo.phoneNumber)
    formValues.contactInfo.phoneNumber = stripDashes(
      formValues.contactInfo.phoneNumber
    );

  formValues.address.verifySkip = true;

  if (formValues.militaryService === 'false')
    formValues.militaryModifiers = null;

  const updatedFullProfile = {
    info: {
      ...fullProfile.info,
      values: {
        ...fullProfile.info.values,
        ...formValues,
      },
    },
    previousValues: cloneObject({ ...fullProfile.info.values }),
  };

  dispatch(storeAppProfile(updatedFullProfile));

  return Promise.resolve(updatedFullProfile);
};

export const submitTDLRLRRenewal = ({ values, formikActions }) => async (
  dispatch,
  getState
) => {
  const previousValues = selectAppProfile(getState()).previousValues;
  const contactInfoUnchanged =
    shallowCompare(values.contactInfo, previousValues.contactInfo) &&
    shallowCompare(
      {
        streetAddress: values.address.streetAddress,
        addressLine2: values.address.addressLine2,
        addressLine3: values.address.addressLine3,
        city: values.address.city,
        country: values.address.country,
        postalCode: values.address.postalCode,
        state: values.address.state,
        zipFive: values.address.zipFive,
      },
      {
        streetAddress: previousValues.address.streetAddress,
        addressLine2: previousValues.address.addressLine2,
        addressLine3: previousValues.address.addressLine3,
        city: previousValues.address.city,
        country: previousValues.address.country,
        postalCode: previousValues.address.postalCode,
        state: previousValues.address.state,
        zipFive: previousValues.address.zipFive,
      }
    );

  await to(dispatch(updateTDLRLRFullProfile({ values, formikActions })));
  const state = getState();

  const basicInfo = selectLicense(state);
  const fullProfile = selectAppProfile(state);

  const {
    felonyMisdemeanor,
    criminalOffense,
    continuingEducation,
  } = fullProfile.info.values;

  // Converting to array if not null per request from backend (Sai Muni)
  const militaryModifiers =
    fullProfile.info.values.militaryModifiers &&
    fullProfile.info.values.militaryModifiers.code
      ? [fullProfile.info.values.militaryModifiers]
      : null;

  const requestBody = {
    appId: basicInfo.appId,
    userKeys: [basicInfo.userKey],
    agencyId: basicInfo.agencyId,
    profileInfo: basicInfo,
    transactionInfo: {
      ...fullProfile.info.values,
      felonyMisdemeanor: convertStringToBoolean(felonyMisdemeanor),
      criminalOffense: convertStringToBoolean(criminalOffense),
      continuingEducation: convertStringToBoolean(continuingEducation),
      militaryModifiers,
      contactInfoUnchanged,
    },
    transactionId: APP_TRANSACTION_IDS.SUBMIT_RENEWAL_REQUEST,
  };

  const [{ response, body }, err] = await to(
    dispatch(submitTDLRLRRenewalRequest(requestBody))
  );

  if (err || checkRequestFailure({ response, body })) {
    handleRequestFailure(body, formikActions);
    return Promise.reject(err || response);
  }

  // Update basicInfo with renewalFee data received from response
  dispatch(
    storeBasicInfo({ ...basicInfo, ...body.data, selectedLicense: basicInfo })
  );

  if (body.status === 'SUCCESS') return dispatch(push('/tdlrlr/payment'));
};

export const clearTDLRLRData = () => dispatch => {
  dispatch(clearAppData());
  return dispatch({ type: TDLR_TDLRLR_CLEAR_DATA });
};
