import SelectPaymentAccount from './SelectPaymentAccount/SelectPaymentAccount';
import PaymentForm from './PaymentForm/PaymentForm';
import PaymentError from './PaymentError/PaymentError';

export const PAYMENT_STEPS = {
  SELECT_PAYMENT: 'select',
  PAYMENT_FORM: 'form',
  SUCCESS: 'success',
  ERROR: 'error',
};

export const steps = [
  { id: PAYMENT_STEPS.SELECT_PAYMENT, component: SelectPaymentAccount },
  { id: PAYMENT_STEPS.PAYMENT_FORM, component: PaymentForm },
  { id: PAYMENT_STEPS.ERROR, component: PaymentError },
];
