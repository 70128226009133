export function selectRegistration({ dmv: { selected } }) {
  return selected;
}

export function selectCounty({ dmv: { appProfile } }) {
  return appProfile ? appProfile.countyCode : '';
}

export function selectRegAddlYrElgble({ dmv: { appProfile } }) {
  return (appProfile && appProfile.regAddlYrElgble === null) || undefined
    ? false
    : appProfile.regAddlYrElgble === 'true';
}

export function renewalState({ dmv: { appProfile } }) {
  return appProfile ? appProfile.eligibilityState : '';
}

export function fullExpirationDate({ dmv: { appProfile } }) {
  return appProfile ? appProfile.vehicle.expirationDate : '';
}

export function selectRenewalTerm({ dmv: { appProfile } }) {
  return appProfile ? appProfile.renewalTerm : '';
}

export function selectLookUp({ dmv: { lookup } }) {
  return lookup;
}

export function getRegExpirationDateForComparison({ dmv: { selected } }) {
  return selected.registrationExpiryYear && selected.registrationExpiryMonth
    ? selected.registrationExpiryYear + selected.registrationExpiryMonth
    : '';
}

export function selectMailingAddress({ dmv: { appProfile } }) {
  return appProfile ? appProfile.mailingAddress : '';
}

export function selectSelfCertifiedIndicator({ dmv: { appProfile } }) {
  return appProfile ? appProfile.selfCertifiedIndicator : '';
}

export function selectInspectionResult({ dmv: { appProfile } }) {
  return appProfile ? appProfile.inspectionResult : '';
}

export function selectIsOutOfState({ dmv: { appProfile } }) {
  return appProfile ? appProfile.isOutOfState : '';
}

export function selectCommercialVehicle({ dmv: { commercial } }) {
  if (typeof commercial === 'string') {
    return commercial === 'true' ? true : false;
  }
  return commercial ? true : false;
}
