// Vendor
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// Components
import { Trans, CreditCardIcon } from '../..';
import { TxPaymentCardForm, TxPaymentCardFormMode } from 'texkit-ui/forms';
import { PaymentCardFormWrapper } from '../';

const AddPaymentCard = ({ formikProps, showForm, onCardFieldMessage }) => {
  const { selectedLanguage } = useSelector(({ language }) => ({
    selectedLanguage: language.selected,
  }));

  return (
    <>
      <div className={'payment-card'}>
        <CreditCardIcon name="blank" />
        <div className="payment-add-text">
          <Trans file="Payment" id="AddCard" fallback="Add Card" />
        </div>
      </div>
      {showForm && (
        <PaymentCardFormWrapper formikProps={formikProps}>
          <TxPaymentCardForm
            displayName="TxPaymentCardForm"
            billingAddressTitle={
              <Trans file="Payment" id="BillingAddressTitle" />
            }
            billingAddressSubTitle={
              <Trans file="Payment" id="BillingAddressSubTitle" />
            }
            paymentMethodTitle={
              <Trans file="Payment" id="CardInformationTitle" />
            }
            paymentMethodSubTitle={
              <Trans file="Payment" id="PaymentMethodSubTitle" />
            }
            mode={TxPaymentCardFormMode.TransactionAdd}
            onCardFieldMessage={onCardFieldMessage}
            spanish={selectedLanguage === 'es'}
          />
        </PaymentCardFormWrapper>
      )}
    </>
  );
};

AddPaymentCard.propTypes = {
  showForm: PropTypes.bool.isRequired,
  formikProps: PropTypes.object.isRequired,
  onCardFieldMessage: PropTypes.func.isRequired,
};

export default AddPaymentCard;
