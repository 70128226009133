//@flow

// Vendors
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components
import { Modal } from '..';
import Trans from '../../Trans/Trans';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';

// Actions
import { closeModal } from '../../../../reducers/modal/modalActions';

// Helpers
import { publicURL } from '../../../../lib/helpers';
import { selectModalData } from '../../../../reducers/modal/modalSelectors';

// Styles
import './whats-this-modal.scss';

const WhatsThisModal = () => {
  const dispatch = useDispatch();
  const { showAmex } = useSelector(selectModalData);

  return (
    <Modal name="WhatsThisModal" className="whats-this-modal">
      <span className="whats-this-modal__header">
        <Trans file="Payment" id="WhatsThisTitle" />
      </span>
      <div className="whats-this-modal__content">
        <p className="whats-this-modal__sub-header">
          <Trans file="Payment" id="WhatIsThisSubTitle" />
        </p>
        <div className="whats-this-modal__card-display">
          <div>
            <div className="whats-this-modal__card-display-header">
              <Trans file="Payment" id="MastercardVisaDiscover" />
            </div>
            <div className="whats-this-modal__card-description mb-3">
              <Trans file="Payment" id="MastercardVisaDiscoverDesc" />
            </div>
            <img
              src={publicURL('mc_visa_discover_cvv.png')}
              width="304px"
              height="215px"
            />
          </div>
          {showAmex && (
            <div>
              <div className="whats-this-modal__card-display-header">
                <Trans file="Payment" id="AmericanExpress" />
              </div>
              <div className="whats-this-modal__card-description mb-3">
                <Trans file="Payment" id="AmericanExpressDesc" />
              </div>
              <img
                src={publicURL('american_express_cvv.png')}
                width="304px"
                height="215px"
              />
            </div>
          )}
        </div>
      </div>
      <div className="whats-this-modal__footer">
        <ButtonPrimary
          className="modal-button"
          onClick={() => dispatch(closeModal('WhatsThisModal'))}
        >
          <Trans file="Labels" id="gotIt" />
        </ButtonPrimary>
      </div>
    </Modal>
  );
};

export default WhatsThisModal;
