//@flow

import { REQUEST_TYPES, REQUEST_LOCATIONS } from '.';

export const getPaymentAccountsRequest = (app: string): RequestOptions => {
  return {
    type: `[API] Payment Request`,
    url: `/userwallet/v2/getAllPaymentCards${app ? `/?app=${app}` : ''}`,
    APIMethod: REQUEST_TYPES.GET,
  };
};

export const deletePaymentAccountRequest = (body: {
  paymentAccountId: string,
}): RequestOptions => ({
  type: `[API] Delete Payment Account`,
  url: `/userwallet/v2/wallet/paymentAccount/deleteCard`,
  APIMethod: REQUEST_TYPES.POST,
  body,
});

export const addPaymentAccountRequest = (body: Object): RequestOptions => ({
  type: `[API] Add Payment Card Request`,
  url: '/userwallet/v2/wallet/paymentAccount/addCard',
  APIMethod: REQUEST_TYPES.POST,
  body,
});

export const editPaymentAccountRequest = (body: Object): RequestOptions => ({
  type: `[API] Edit Payment Card Request`,
  url: '/userwallet/v2/wallet/paymentAccount/updateCard',
  APIMethod: REQUEST_TYPES.POST,
  body,
});

export const getPaymentAccountRequest = (params: {
  paymentAccountId: string,
}): RequestOptions => ({
  type: `[API] Get One Payment Account`,
  url: `/userwallet/v2/wallet/paymentAccount/editCard/${params.paymentAccountId}`,
  APIMethod: REQUEST_TYPES.GET,
});

export const getAddPaymentAccountFrameUrlRequest = (): RequestOptions => ({
  type: `[API] Get iFrame URL for Adding Payment Method`,
  url: `/user/ui/wallet/paymentAccount/authorize`,
  APIMethod: REQUEST_TYPES.GET,
});

export const getEditPaymentAccountFrameUrlRequest = (body: {
  paymentAccountId: string,
}): RequestOptions => ({
  type: `[API] Get iFrame URL for Editing Payment Method`,
  url: `/user/ui/wallet/paymentAccount/edit`,
  APIMethod: REQUEST_TYPES.POST,
  body,
});

export const getPaymentFormFrameUrlRequest = (): RequestOptions => {
  const pathArr = window.location.pathname.split('/');

  //add app argument only for dmv
  const app = pathArr.find(path => path === 'dmvrr');
  return {
    type: `[API] Get iFrame URL for the Payment Form`,
    url: app
      ? `/user/ui/wallet/paymentForm?app=${app}`
      : `/user/ui/wallet/paymentForm`,
    APIMethod: REQUEST_TYPES.GET,
  };
};

export const getPaymentCardFrameUrlRequest = (
  params: string
): RequestOptions => {
  return {
    type: `[API] Get iFrame URL for the Payment Card`,
    url: `/user/ui/getPaymentAccountForm/?${params}`,
    APIMethod: REQUEST_TYPES.GET,
  };
};

// TODO: Remove IP calls and environment variables from the project since it is no longer used
export const clientIPRequest = () => ({
  type: `[API] Client IP Request`,
  location: REQUEST_LOCATIONS.IP,
  APIMethod: REQUEST_TYPES.GET,
});
