// Vendors
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Selectors
import { selectModalData } from '../../../../reducers/modal/modalSelectors';

// Components
import { Modal, Trans } from '../..';
import { closeModal } from '../../../../reducers/modal/modalActions';

//Styles
import './payment-form-error-modal.scss';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';

const PaymentFormErrorModal = () => {
  const dispatch = useDispatch();
  const { onClose = () => {} } = useSelector(selectModalData);

  return (
    <Modal
      name="PaymentFormErrorModal"
      className="payment-form-error-modal"
      disableOnClickOutside
      onClose={onClose}
    >
      <h2 className="h2 modal-title">
        <Trans file="Modals" id="IframeTimeOutTitle" />
      </h2>
      <div className="modal-content">
        <Trans file="Modals" id="IframeTimeOutBody" />
      </div>
      <ButtonPrimary
        className="modal-close-button"
        onClick={() => {
          dispatch(closeModal('PaymentFormErrorModal'));
        }}
      >
        <Trans file="Modals" id="TimeOutOk" />
      </ButtonPrimary>
    </Modal>
  );
};

export default PaymentFormErrorModal;
