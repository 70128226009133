//@flow

// Vendors
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import classNames from 'classnames';

// Components
import {
  FormActions,
  FormActionsItem,
  FormError,
  LoadingDots,
  Modal,
  Trans,
} from '../../';
import { PaymentCardFormWrapper } from '../../../';
import { TxButton, TxButtonMode } from 'texkit-ui';
import { TxPaymentCardForm, TxPaymentCardFormMode } from 'texkit-ui/forms';

// Actions
import {
  editPaymentAccount,
  getPaymentAccount,
  getPaymentAccounts,
} from '../../../../reducers/payment/paymentActions';
import { openModal, closeModal } from '../../../../reducers/modal/modalActions';

// Selectors
import { selectModalData } from '../../../../reducers/modal/modalSelectors';
import { selectAppLanguage } from '../../../../reducers/app/appSelectors';

// Helpers
import { formatExpirationDate } from '../../../../lib/helpers';
import { states } from '../../../../lib/data';
import { selectAppSlugByPath } from '../../../../reducers/agency/agencySelectors';

import './edit-payment-account-modal.scss';

const serverErrors = {
  MGMW_EX_4000: {
    title: 'WalletBadPayloadTitle',
    id: 'WalletBadPayloadDesc',
    type: 'danger',
  },
  MGMW_EX_4001: {
    title: 'WalletServerErrorTitle',
    id: 'WalletServerErrorDesc',
    type: 'warning',
  },
};

// Switch to adjusted SCSS if on iOS device
const userAgent = window.navigator.userAgent.toLowerCase();
const ios = /iphone|ipod|ipad/.test(userAgent);

const EditPaymentAccountModal = () => {
  const formikPropsRef = useRef();
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState();
  const [errorInfo, setErrorInfo] = useState();
  const { paymentAccount } = useSelector(selectModalData);
  const selectedLanguage = useSelector(selectAppLanguage);

  const fetchPaymentAccount = async paymentAccount => {
    setErrorInfo();
    try {
      const { paymentAccountId } = paymentAccount;
      const { status, data } = await dispatch(
        getPaymentAccount(paymentAccountId)
      );

      if (status === 'ERROR') {
        dispatch(openModal('ServerErrorModal'));
        setInitialValues();
        return;
      }

      const { cardLast4, expirationDate } = data;
      const address = data.paymentRequest.address || {};
      const state = states.find(state => state.value === address.stateCd);

      const initialAccountInfo = {
        cardNumber: cardLast4,
        expirationDate: formatExpirationDate(expirationDate),
        name: data.paymentRequest.paymentAccountName || '',
        streetAddress: address.addressLine1,
        streetAddress2: address.addressLine2,
        city: address.city,
        state: state ? state.label : '',
        zip: address.zipCode,
      };

      setInitialValues(initialAccountInfo);
      setTimeout(() => {
        if (formikPropsRef.current) {
          formikPropsRef.current.validateForm();
        }
      }, 0);
    } catch (error) {
      console.error('Payment account error', error);
    }
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    const { paymentAccountId } = paymentAccount;
    const expirationDate = values.expirationDate.replace('/', '');
    const addressLine2 = values.streetAddress2 || '';
    const state = states.find(state => state.label === values.state);

    const payload = {
      paymentAccountId,
      expirationDate,
      paymentAccountName: values.name,
      address: {
        streetAddress: values.streetAddress,
        addressLine2,
        city: values.city,
        state: state.value || '',
        postalCode: values.zip,
      },
    };

    try {
      await dispatch(editPaymentAccount(payload));
      dismissModal();
    } catch (error) {
      const { code } = error;
      if (code) {
        setErrorInfo(serverErrors[code]);
      } else {
        console.error('Payment account error');
      }
    } finally {
      setSubmitting(false);
    }

    return;
  };

  const currentAppSlug = useSelector(selectAppSlugByPath);
  const isAccountSettings = currentAppSlug === 'accountSettings';

  const dismissModal = () => {
    formikPropsRef.current = null;
    setInitialValues();
    dispatch(getPaymentAccounts());
    dispatch(closeModal('EditPaymentAccountModal'));
  };

  return (
    <Modal
      name="EditPaymentAccountModal"
      className={
        ios ? 'edit-payment-account-modal ios' : 'edit-payment-account-modal'
      }
      disableOnClickOutside="true"
      closeButtonClassName="modal-close-button"
      onClose={dismissModal}
      onOpen={() => fetchPaymentAccount(paymentAccount)}
    >
      <h2 className="h1 modal-title">
        <Trans
          file="Payment"
          id="UpdateCardInformation"
          fallback="Update card information"
        />
      </h2>
      {!initialValues ? (
        <LoadingDots />
      ) : (
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validateOnBlur={false}
          validateOnChange={false}
        >
          {formikProps => {
            if (!formikPropsRef.current) {
              formikPropsRef.current = formikProps;
            }
            const { isSubmitting, isValid } = formikProps;

            return (
              <form onSubmit={formikProps.handleSubmit} noValidate>
                <PaymentCardFormWrapper formikProps={formikProps}>
                  <TxPaymentCardForm
                    displayName="TxPaymentCardForm"
                    paymentMethodTitle={
                      <Trans file="Payment" id="CardInformationTitle" />
                    }
                    paymentMethodSubTitle={
                      !isAccountSettings && (
                        <Trans file="Payment" id="PaymentMethodSubTitle" />
                      )
                    }
                    billingAddressTitle={
                      <Trans file="Payment" id="BillingAddressTitle" />
                    }
                    billingAddressSubTitle={
                      !isAccountSettings && (
                        <Trans file="Payment" id="BillingAddressSubTitle" />
                      )
                    }
                    mode={TxPaymentCardFormMode.Edit}
                    spanish={selectedLanguage === 'es'}
                  />
                  <FormError
                    hasError={Boolean(errorInfo)}
                    message={errorInfo}
                    formikProps={formikProps}
                  />

                  <FormActions>
                    <FormActionsItem
                      className={classNames({
                        'spanish-only': selectedLanguage === 'es',
                      })}
                    >
                      <TxButton
                        mode={TxButtonMode.Primary}
                        type="submit"
                        disabled={!isValid || isSubmitting}
                      >
                        <Trans file="Payment" id="SavePaymentMethod" />
                      </TxButton>
                    </FormActionsItem>
                  </FormActions>
                </PaymentCardFormWrapper>
              </form>
            );
          }}
        </Formik>
      )}
    </Modal>
  );
};

export default EditPaymentAccountModal;
