//@flow

import { REQUEST_TYPES } from '.';
import { APP_TRANSACTION_IDS } from '../reducers/app/appActions';

export const submitPayment = (
  paymentData: Object,
  basicInfo: Object
): RequestOptions => ({
  type: `[API] Payment Request`,
  url: '/agency/app/transact',
  APIMethod: REQUEST_TYPES.POST,
  body: {
    userKeys: [basicInfo.userKey],
    agencyId: basicInfo.agencyId,
    appId: basicInfo.appId,
    profileInfo: basicInfo.selectedLicense,
    transactionInfo: {
      ...paymentData,
      renewalFee: basicInfo.renewalFee,
      feeCodes: basicInfo.feeCodes,
    },
    transactionId: basicInfo.jointRenewalUpgradeFlag
      ? APP_TRANSACTION_IDS.SUBMIT_UPGRADE_RENEWAL_PAYMENT
      : basicInfo.upgradeFlag
      ? APP_TRANSACTION_IDS.SUBMIT_UPGRADE_PAYMENT
      : APP_TRANSACTION_IDS.SUBMIT_RENEWAL_PAYMENT,
  },
});

export const submitDLRPayment = (
  paymentData: Object = {},
  basicInfo: Object
): RequestOptions => {
  const { address: billingAddress = {} } = paymentData;
  if (paymentData && paymentData.address) delete paymentData.address;

  return {
    type: `[API] Payment Request`,
    url: '/agency/app/transact',
    APIMethod: REQUEST_TYPES.POST,
    body: {
      userKeys: [basicInfo.userKey],
      agencyId: basicInfo.agencyId,
      appId: basicInfo.appId,
      profileInfo: { userKey: basicInfo.userKey },
      transactionInfo: {
        ...paymentData,
        billingAddress,
        cardType: basicInfo.licenseType,
        usCitizen: basicInfo.usCitizen,
        renewalFee:
          basicInfo.upgradeFlag && !basicInfo.jointRenewalUpgradeFlag
            ? basicInfo.upgradeFee
            : basicInfo.renewalFee,
        organDonorOption: basicInfo.organDonorOption,
        voterRegistration: basicInfo.usCitizen
          ? basicInfo.voterRegistration
            ? 'Y'
            : 'N'
          : undefined,
        volunteerElectionJudge: basicInfo.volunteerElectionJudge,
        voterRegistrationApplicationType: basicInfo.voterRegistrationAppType,
        surrenderCardOption: basicInfo.surrenderCardOption,
        serviceFee: basicInfo.serviceFee,
        emergencyContactsFailedStatus: basicInfo.emergencyContactsFailedStatus,
        mailingSameAsHomeAddress: basicInfo.mailingSameAsHomeAddress,
        mailingAddress: basicInfo.renewalMailingAddress,
        homeAddress: basicInfo.renewalHomeAddress,
        cdlInformation:
          basicInfo.licenseType === 'CDL'
            ? {
                restrictionCodes: basicInfo.cdlInformation.restrictionCodes,
                endorsementCodes: basicInfo.cdlInformation.endorsementCodes,
              }
            : {},
        primaryEmergencyContactInfo:
          basicInfo.emergencyContacts.primaryEmergencyContactInfo,
        secondryEmergencyContactInfo:
          basicInfo.emergencyContacts.secondryEmergencyContactInfo,
        transactionAmounts: {
          bestDonationFee: (
            Number.parseInt(basicInfo.donationValue.bestDonationOption) || ''
          ).toString(),
          bestDonationOption:
            basicInfo.donationOption.bestDonationOption &&
            basicInfo.donationValue.bestDonationOption > 0,
          glendaDonationOption:
            basicInfo.donationOption.glendaDonationOption &&
            basicInfo.donationValue.glendaDonationOption > 0,
          glendaDonationFee: (
            Number.parseInt(basicInfo.donationValue.glendaDonationOption) || ''
          ).toString(),
          fvaDonationOption:
            basicInfo.donationOption.fvaDonationOption &&
            basicInfo.donationValue.fvaDonationOption > 0,
          fvaDonationFee: (
            Number.parseInt(basicInfo.donationValue.fvaDonationOption) || ''
          ).toString(),
          evidenceTestingDonationOption:
            basicInfo.donationOption.evidenceTestingDonationOption &&
            basicInfo.donationValue.evidenceTestingDonationOption > 0,
          evidenceTestingDonationFee: (
            Number.parseInt(
              basicInfo.donationValue.evidenceTestingDonationOption
            ) || ''
          ).toString(),
          youthFundDonationOption:
            basicInfo.donationOption.youthFundDonationOption &&
            basicInfo.donationValue.youthFundDonationOption > 0,
          youthFundDonationFee: (
            Number.parseInt(basicInfo.donationValue.youthFundDonationOption) ||
            ''
          ).toString(),
          subTotal: basicInfo.donationValue.total || '0.00',
        },
      },
      transactionId: basicInfo.jointRenewalUpgradeFlag
        ? APP_TRANSACTION_IDS.SUBMIT_UPGRADE_RENEWAL_PAYMENT
        : basicInfo.upgradeFlag
        ? APP_TRANSACTION_IDS.SUBMIT_UPGRADE_PAYMENT
        : basicInfo.renewAddressChangeFlag
        ? APP_TRANSACTION_IDS.REPLACEMENT
        : APP_TRANSACTION_IDS.SUBMIT_RENEWAL_PAYMENT,
    },
  };
};

export const submitDMVPayment = (body: Object): RequestOptions => ({
  type: `[API] Payment Request`,
  url: '/agency/app/transact',
  APIMethod: REQUEST_TYPES.POST,
  body,
});
