// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import { formikInjectedPropsTypes } from '../../../lib/validation/propTypes/formikPropTypes';

import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';

// Components
import {
  InputCheckbox,
  Trans,
  InputRadioGroup,
  EditPaymentAccountModal,
} from '../..';
import { LoadingDots } from '../../common';
import { AddPaymentCard, PaymentCard } from '..';
import { WhatsThisModal } from '../../common';

// Selectors
import { selectPaymentAccounts } from '../../../reducers/payment/paymentSelectors';
import { selectPathname } from '../../../reducers/route/routeSelectors';

// Actions
import { openModal } from '../../../reducers/modal/modalActions';

// Helpers
import { isDateExpired } from '../../../lib/helpers/util';

const PaymentSelectCardList = ({
  formikProps,
  onCardFieldMessage,
  onChange = () => {},
}) => {
  const dispatch = useDispatch();
  const currentPath = useSelector(selectPathname);
  const paymentAccounts = useSelector(selectPaymentAccounts);
  const setFieldValue = formikProps.setFieldValue;

  if (!paymentAccounts) {
    return <LoadingDots />;
  }

  const inputOptions = paymentAccounts.map((paymentAccount, index) => {
    let isCardSelected =
      formikProps.values.selectPayment &&
      formikProps.values.selectPayment.paymentAccountId ===
        paymentAccount.paymentAccountId;

    const onEdit = () => {
      dispatch(openModal('EditPaymentAccountModal', { paymentAccount }));
    };

    const isCardExpired = isDateExpired(paymentAccount.expirationDate);
    return {
      className: classNames('payment-select-list-item', {
        'selected-payment': isCardSelected,
        disabled: isCardExpired,
      }),
      id: `selectPayment${index}`,
      value: paymentAccount,
      disabled: isCardExpired,
      label: (
        <PaymentCard
          paymentAccount={paymentAccount}
          formikProps={formikProps}
          showCvv={
            (currentPath.includes('dmvrr') ||
              currentPath.includes('dpslr') ||
              currentPath.includes('tdlrlr')) &&
            isCardSelected
          }
          showEditButton={true}
          onEdit={onEdit}
          isCardExpired={isCardExpired}
        />
      ),
    };
  });

  inputOptions.push({
    className: classNames('payment-select-list-item', 'add-card-item', {
      'selected-payment':
        formikProps.values.selectPayment.paymentAccountId === 'addcard',
    }),
    id: `selectPayment${inputOptions.length}`,
    value: {
      paymentAccountId: 'addcard',
    },
    label: (
      <AddPaymentCard
        onCardFieldMessage={onCardFieldMessage}
        formikProps={formikProps}
        showForm={
          formikProps.values.selectPayment.paymentAccountId === 'addcard'
        }
      />
    ),
  });

  const onChangeCallback = event => {
    if (setFieldValue) {
      if (formikProps.values.cvv) {
        setFieldValue('cvv', '');
      }
    }
    onChange(event.target.value);
  };

  return (
    <>
      <div className="payment-select">
        <div className="payment-select-list">
          {inputOptions && inputOptions.length > 0 && (
            <InputRadioGroup
              name="selectPayment"
              optionValueKey="paymentAccountId"
              formikProps={formikProps}
              options={inputOptions}
              onChange={onChangeCallback}
            />
          )}
          {formikProps.values.selectPayment.paymentAccountId === 'addcard' && (
            <InputCheckbox
              formikProps={formikProps}
              className="saveCardCheckbox addCard"
              name="savePaymentAccount"
              label={<Trans file="Payment" id="SaveCardToAccount" />}
            />
          )}
        </div>
      </div>
      <EditPaymentAccountModal />
      <WhatsThisModal />
    </>
  );
};

PaymentSelectCardList.propTypes = {
  formikProps: formikInjectedPropsTypes.isRequired,
  onCardFieldMessage: PropTypes.func.isRequired,
  onChange: PropTypes.func,
};

export default PaymentSelectCardList;
