// Vendors
import React, { useCallback, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';

// Actions
import { submitPaymentFromForm } from '../../../../reducers/payment/paymentActions';
import { goBack } from 'connected-react-router';

// Components
import { LoadingDots } from '../../../common';
import {
  InputCheckbox,
  Trans,
  FormActions,
  FormActionsItem,
  FormError,
} from '../../../../components';
import { TxPaymentCardForm, TxPaymentCardFormMode } from 'texkit-ui/forms';
import { TxButton, TxButtonMode } from 'texkit-ui';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';
import { PaymentCardFormWrapper } from '../../';

// Helpers
import { PaymentContext } from '../../Payment';
import { states } from '../../../../lib/data';
import { paymentFormSchema } from '../../../../lib/validation';
import { APP_CONSTANTS } from '../../../../lib/data';

const PaymentForm = () => {
  const paymentContext = useContext(PaymentContext);
  const dispatch = useDispatch();
  const [cardData, setCardData] = useState({});
  const [submitting, setSubmitting] = useState();
  const [savePaymentAccount, setSavePaymentAccount] = useState(false);
  const [paymentErrors, setPaymentErrors] = useState({});
  const { selectedLanguage } = useSelector(({ language }) => ({
    selectedLanguage: language.selected,
  }));

  const onCancel = () => {
    dispatch(goBack());
    setPaymentErrors({});
  };

  const onSubmit = async (values, formikActions) => {
    try {
      // Revalidate the cvv field with the current card data before submitting
      paymentFormSchema.validateSyncAt(
        'cvv',
        { cvv: values.cvv },
        { context: cardData }
      );

      const state = states.find(state => state.label === values.state);
      const paymentPayload = {
        nameOnCard: values.name,
        cardNumber: cardData.token ? cardData.token.slice(-4) : '',
        paymentToken: cardData.token || '',
        paymentAccountType: APP_CONSTANTS.CARD_TYPES[cardData.cardtype] || '',
        expirationDate: values.expirationDate.replace('/', ''),
        saveSelectedCard: savePaymentAccount,
        cvv: values.cvv,
        address: {
          address1: values.streetAddress,
          address2: values.streetAddress2 || '',
          city: values.city,
          state: state.value || '',
          postalCode: values.zip,
          countryId: '1',
          country: 'USA',
        },
      };

      setSubmitting(true);
      await dispatch(
        submitPaymentFromForm(paymentContext, paymentPayload, formikActions)
      ).catch(error => {
        if (error.serverErrors) {
          setPaymentErrors({
            status: {
              serverErrors: error.serverErrors,
            },
          });
        }
      });
    } catch (error) {
      if (error.name === 'ValidationError') {
        formikActions.setFieldError('cvv', error.message);
      } else {
        console.error('Error submitting payment form', error);
      }
    } finally {
      setSubmitting(false);
      formikActions.setSubmitting(false);
    }
  };

  const allFieldsTouched = useCallback(touchedMap => {
    const allFields = Object.keys(paymentFormSchema.fields).filter(
      field => field !== 'streetAddress2'
    );
    const touchedFields = Object.keys(touchedMap);
    return allFields.every(field => touchedFields.includes(field));
  }, []);

  return (
    <>
      {submitting ? (
        <LoadingDots />
      ) : (
        <Formik
          onSubmit={onSubmit}
          validateOnBlur={false}
          validateOnChange={false}
        >
          {formikProps => {
            const { isSubmitting, isValid, touched } = formikProps;
            const allFieldsAreTouched = allFieldsTouched(touched);

            return (
              <form onSubmit={formikProps.handleSubmit} noValidate>
                <PaymentCardFormWrapper formikProps={formikProps}>
                  <TxPaymentCardForm
                    displayName="TxPaymentCardForm"
                    billingAddressTitle={
                      <Trans file="Payment" id="BillingAddressTitle" />
                    }
                    billingAddressSubTitle={
                      <Trans file="Payment" id="BillingAddressSubTitle" />
                    }
                    paymentMethodTitle={
                      <Trans file="Payment" id="PaymentMethodTitle" />
                    }
                    paymentMethodSubTitle={
                      <Trans file="Payment" id="PaymentMethodSubTitle" />
                    }
                    onCardFieldMessage={fdmsMessage => setCardData(fdmsMessage)}
                    mode={TxPaymentCardFormMode.TransactionAdd}
                    spanish={selectedLanguage === 'es'}
                  />
                  <InputCheckbox
                    className="saveCardCheckbox"
                    name="savePaymentAccount"
                    label={<Trans file="Payment" id="SaveCardToAccount" />}
                    checked={savePaymentAccount}
                    onChange={event =>
                      setSavePaymentAccount(!!event.target.checked)
                    }
                  />
                  <FormError formikProps={paymentErrors} />
                </PaymentCardFormWrapper>

                <FormActions>
                  <FormActionsItem>
                    <ButtonPrimary
                      type="submit"
                      disabled={
                        !isValid ||
                        !allFieldsAreTouched ||
                        !cardData.token ||
                        isSubmitting
                      }
                      loadingTrans={
                        <Trans file="Payment" id="Paying" fallback="Paying" />
                      }
                    >
                      <Trans file="Payment" id="PayNow" fallback="Pay Now" />
                    </ButtonPrimary>
                  </FormActionsItem>
                  <FormActionsItem>
                    <TxButton
                      mode={TxButtonMode.Secondary}
                      onClick={onCancel}
                      disabled={formikProps.isSubmitting}
                    >
                      <Trans file="Labels" id="Back" fallback="Back" />
                    </TxButton>
                  </FormActionsItem>
                </FormActions>
              </form>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default PaymentForm;
