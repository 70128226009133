export default {
  NATIVE_SIGN_OUT_MSG: {
    event: 'signOut',
  },
  SOCURE_DOWN_MSG: {
    event: 'socureDown',
  },
  CARD_TYPES: {
    VISA: 'VISA',
    DISCOVER: 'DISC',
    AMEX: 'AMEX',
    MC: 'MC',
  },
  AGENCY_PAYMENT_URLS: ['/dmvrr/payment', '/dpslr/payment', '/tdlrlr/payment'],
};
