/**
 * PaymentCardFormHandler component is responsible for handling the payment card form.
 * It provides validation for form fields and fetches the iframe URL for the payment card form.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The child component, which must be exactly one.
 * @param {Object} props.formikProps - The Formik props for form handling.
 *
 * @throws {Error} Throws an error if the component does not have exactly one child.
 *
 * @example
 * <PaymentCardFormHandler formikProps={formikProps}>
 *   <TxPaymentCardForm
 *       billingAddressTitle={<Trans file="Payment" id="BillingAddressTitle" />}
 *       paymentMethodTitle={<Trans file="Payment" id="CardInformationTitle" />}
 *       mode={TxPaymentCardFormMode.Edit}
 *   />
 * </PaymentCardFormHandler>
 *
 * @returns {React.Element} The cloned child element with injected props for handling form validation and iframe URL.
 */

import React, {
  Children,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import PropTypes from 'prop-types';

// Vendors
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TxPaymentCardFormMode } from 'texkit-ui/forms';

// Actions
import {
  setPaymentIframeFetchAttempts,
  resetPaymentIframeFetchAttempts,
  getPaymentCardFrameUrl,
} from '../../../reducers/payment/paymentActions';
import { openModal } from '../../../reducers/modal/modalActions';

// Helpers
import { paymentFormSchema } from '../../../lib/validation';
import { removeFieldFromValidationSchema } from '../../../lib/helpers';
import ERRORS from '../../../lib/validation/errorCopy';
import {
  bodyHasError,
  walletErrors,
} from '../../../lib/validation/serverErrors';
import { APP_CONSTANTS } from '../../../lib/data';

// Selectors
import { selectPathname } from '../../../reducers/route/routeSelectors';
import { selectAppSlugByPath } from '../../../reducers/agency/agencySelectors';
import { selectPaymentIframeFetchAttempts } from '../../../reducers/payment/paymentSelectors';

// Constants
const MAX_FETCH_ATTEMPTS = 4;

const isAmexAccepted = currentAppSlug =>
  ['tdlrlr', 'accountSettings', 'dpslr'].includes(currentAppSlug);

const clearSelectedPaymentAccount = formikProps => {
  if (formikProps.values.selectPayment) {
    formikProps.setFieldValue('selectPayment', {});
    // Work around to move focus away from the currently focused element
    if (document.activeElement) {
      setTimeout(() => {
        document.activeElement.blur();
      }, 400);
    }
  }
};

const findChildByType = (children, type) => {
  const childArray = Children.toArray(children);
  return childArray.find(child => {
    return (
      child.type && [child.type.name, child.props.displayName].includes(type)
    );
  });
};

const PaymentCardFormWrapper = ({ children, formikProps }) => {
  const [iFrameUrl, setIFrameUrl] = useState('about:blank');
  const dispatch = useDispatch();
  const { t: translate } = useTranslation('Errors');
  const currentAppSlug = useSelector(selectAppSlugByPath);
  const storedFetchAttempts = useSelector(selectPaymentIframeFetchAttempts);
  const urlFetchAttempts = useRef(storedFetchAttempts);
  const currentPath = useSelector(selectPathname);

  const fetchIframeUrl = async () => {
    try {
      const url = await dispatch(getPaymentCardFrameUrl());
      setIFrameUrl(url);
      urlFetchAttempts.current = 0;
      dispatch(resetPaymentIframeFetchAttempts());
    } catch (error) {
      const isWalletError = bodyHasError(error.body, {
        containsExactError: walletErrors,
      });

      // Handle with Houston error instead
      if (!isWalletError) {
        return;
      }

      if (urlFetchAttempts.current < MAX_FETCH_ATTEMPTS) {
        urlFetchAttempts.current += 1;
        await dispatch(setPaymentIframeFetchAttempts(urlFetchAttempts.current));
        openErrorModal();
      } else {
        openHoustonErrorModal(error);
      }
    }
  };

  useEffect(() => {
    const paymentForm = findChildByType(children, 'TxPaymentCardForm');
    if (paymentForm.props.mode !== TxPaymentCardFormMode.Edit) {
      fetchIframeUrl();
    }
  }, []);

  const openErrorModal = useCallback(() => {
    dispatch(
      openModal('PaymentFormErrorModal', {
        onClose: () => {
          clearSelectedPaymentAccount(formikProps);

          // only auto re-fetch on the guest checkout pages
          const paymentUrls = APP_CONSTANTS.AGENCY_PAYMENT_URLS;
          if (![...paymentUrls, '/account'].includes(currentPath)) {
            fetchIframeUrl();
          }
        },
      })
    );
  }, []);

  const openHoustonErrorModal = useCallback(error => {
    const ref =
      error && error.body && error.body.errors && error.body.errors.REF;

    dispatch(
      openModal('ServerErrorModal', {
        REF: ref,
        navigateOnClose: false,
        onClose: () => clearSelectedPaymentAccount(formikProps),
      })
    );
  }, []);

  const translateErrors = useCallback((errors = {}) => {
    const convertedErrors = {};
    for (const key in errors) {
      if (errors.hasOwnProperty(key)) {
        convertedErrors[key] = translate(errors[key].id);
      }
    }
    return convertedErrors;
  }, []);

  const modifiedSchema = useMemo(() => {
    const paymentForm = findChildByType(children, 'TxPaymentCardForm');
    const mode = paymentForm.props.mode;

    if (mode === TxPaymentCardFormMode.Edit) {
      return removeFieldFromValidationSchema(paymentFormSchema, 'cvv');
    }
    return paymentFormSchema;
  }, [children]);

  const acceptedCards = [
    'VISA',
    'MasterCard',
    'Discover',
    isAmexAccepted(currentAppSlug) && 'American Express',
  ].filter(Boolean);

  /*
    Formik's validateOnBlur option triggers validation for the entire form 
    when any field is blurred. To validate only the field that was blurred, 
    validate against the Yup schema directly within the onBlur event 
    handler for each field.
  */
  const handleFieldBlur = event => {
    const fieldName = event.target.name;
    const value = formikProps.values[fieldName];
    const paymentForm = findChildByType(children, 'TxPaymentCardForm');

    try {
      modifiedSchema.validateSyncAt(fieldName, { [fieldName]: value });
      formikProps.setFieldError(fieldName);
    } catch (error) {
      formikProps.setFieldError(fieldName, error.message);
    } finally {
      formikProps.setFieldTouched(fieldName, true);
      if (paymentForm.props.onFieldBlur) {
        paymentForm.props.onFieldBlur(event);
      }
    }
  };

  const handleCardFieldMessage = data => {
    const paymentForm = findChildByType(children, 'TxPaymentCardForm');
    let fdmsMessage = {};

    if (data.success) {
      if (data.cardtype === 'AMEX' && !isAmexAccepted(currentAppSlug)) {
        formikProps.setFieldError(
          'cardNumber',
          ERRORS.FIELDS.CREDITCARD.NUMBER.AMEX_NOT_ACCEPTED
        );
      } else {
        fdmsMessage = data;
        // Clear any previous error for cardNumber
        formikProps.setFieldError('cardNumber');
      }
    } else if (data.success === false) {
      formikProps.setFieldError(
        'cardNumber',
        ERRORS.FIELDS.CREDITCARD.NUMBER.VALID
      );
    }

    if (paymentForm.props.onCardFieldMessage) {
      paymentForm.props.onCardFieldMessage(fdmsMessage);
    }
  };

  if (urlFetchAttempts.current >= MAX_FETCH_ATTEMPTS) {
    return null;
  }

  return (
    <>
      {Children.map(children, child => {
        if (child.props.displayName === 'TxPaymentCardForm') {
          return React.cloneElement(child, {
            acceptedCards,
            iFrameUrl,
            onFieldChange: formikProps.handleChange,
            onFieldBlur: handleFieldBlur,
            onCardFieldMessage: handleCardFieldMessage,
            errors: translateErrors(formikProps.errors),
            values: formikProps.values,
          });
        }
        return child;
      })}
    </>
  );
};

PaymentCardFormWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  formikProps: PropTypes.shape({
    handleChange: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    setFieldError: PropTypes.func,
    setFieldTouched: PropTypes.func,
  }).isRequired,
};

export default PaymentCardFormWrapper;
