// Vendors
import React, { useContext, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

// Components
import { Trans, Form } from '../../..';
import { ButtonPrimary, Button } from 'texkit/dist/components/Buttons';
import PaymentSelectCardList from '../../PaymentSelectCardList/PaymentSelectCardList';

// Actions
import { goBack } from 'connected-react-router';
import {
  clearErrorData,
  submitPaymentFromForm,
  submitPaymentFromAccountList,
} from '../../../../reducers/payment/paymentActions';
import { PaymentContext } from '../../Payment';

// Helpers
import { states } from '../../../../lib/data';
import { paymentFormSchema } from '../../../../lib/validation';
import { APP_CONSTANTS } from '../../../../lib/data';

// Selectors
import { selectPaymentState } from '../../../../reducers/payment/paymentSelectors';
import { selectPathname } from '../../../../reducers/route/routeSelectors';

const PaymentType = ({ paymentState: { paymentInfo = {} } }) => {
  if (paymentInfo.feeInfo) {
    return (
      <h3 className="payment-select-account-title">
        <Trans file="Labels" id="RenewalFee" fallback="Renewal fee" />
      </h3>
    );
  }
  return <p hidden>This hidden content makes the heading accessible.</p>;
};

const SelectPaymentAccount = () => {
  const dispatch = useDispatch();
  const [cardData, setCardData] = useState({});
  const [isAddCard, setIsAddCard] = useState(false);
  const paymentState = useSelector(selectPaymentState);
  const currentPath = useSelector(selectPathname);
  const paymentContext = useContext(PaymentContext);

  const onSubmit = async (values, formikActions) => {
    await dispatch(clearErrorData());
    const isAddCard = values.selectPayment.paymentAccountId === 'addcard';

    if (!isAddCard && Object.keys(values.selectPayment).length) {
      return dispatch(
        submitPaymentFromAccountList(values, formikActions, paymentContext)
      );
    }
    try {
      // Revalidate the cvv field with the current card data before submitting
      paymentFormSchema.validateSyncAt(
        'cvv',
        { cvv: values.cvv },
        { context: cardData }
      );

      const state = states.find(state => state.label === values.state);
      const paymentPayload = {
        nameOnCard: values.name,
        cardNumber: cardData.token ? cardData.token.slice(-4) : '',
        paymentToken: cardData.token || '',
        paymentAccountType: APP_CONSTANTS.CARD_TYPES[cardData.cardtype] || '',
        expirationDate: values.expirationDate.replace('/', ''),
        cvv: values.cvv,
        saveSelectedCard: !!values.savePaymentAccount,
        address: {
          address1: values.streetAddress,
          address2: values.streetAddress2 || '',
          city: values.city,
          state: state.value || '',
          postalCode: values.zip,
          countryId: '1',
          country: 'USA',
        },
      };

      await dispatch(
        submitPaymentFromForm(paymentContext, paymentPayload, formikActions)
      );
    } catch (error) {
      if (error.name === 'ValidationError') {
        formikActions.setFieldError('cvv', error.message);
      } else {
        console.error('Error submitting payment form', error);
      }
    } finally {
      formikActions.setSubmitting(false);
    }
  };

  useEffect(
    () => () => {
      dispatch(clearErrorData());
    },
    [dispatch]
  );

  const validationSchema = useMemo(() => {
    const paymentUrls = APP_CONSTANTS.AGENCY_PAYMENT_URLS;
    if (!isAddCard && paymentUrls.includes(currentPath)) {
      return yup.object().shape({
        cvv: yup
          .string()
          .min(3)
          .max(4)
          .required(),
      });
    }
    return {};
  }, [currentPath, isAddCard]);

  return (
    <div className="payment-select-account">
      <h3 className="payment-select-account-title">
        <PaymentType paymentState={paymentState} />
      </h3>
      <h3>
        <Trans file="Labels" id="PaymentMethod" />
      </h3>

      <Form
        className="payment-select-account-form"
        formikConfig={{
          onSubmit,
          initialValues: { selectPayment: '' },
          validationSchema,
        }}
        render={({ FormError, FormActions, FormActionsItem, formikProps }) => {
          const { values, isSubmitting, isValid } = formikProps;
          const { cvv } = values;
          const isDisabled =
            (isAddCard && !cardData.token) ||
            !isValid ||
            isSubmitting ||
            (cvv && (cvv.length < 3 || cvv === ''));

          return (
            <>
              <PaymentSelectCardList
                formikProps={formikProps}
                onChange={value => setIsAddCard(value === 'addcard')}
                onCardFieldMessage={fdmsMessage => setCardData(fdmsMessage)}
              />
              <FormError />
              <FormActions>
                <FormActionsItem>
                  <ButtonPrimary
                    type="submit"
                    isLoading={isSubmitting}
                    disabled={isDisabled}
                    loadingContent={<Trans file="Labels" id="Submitting" />}
                  >
                    <Trans file="Payment" id="PayNow" fallback="Pay Now" />
                  </ButtonPrimary>
                </FormActionsItem>
                <FormActionsItem>
                  <Button
                    className="payment-button-outline"
                    onClick={() => dispatch(goBack())}
                    disabled={isSubmitting}
                  >
                    <Trans file="Labels" id="Back" fallback="Back" />
                  </Button>
                </FormActionsItem>
              </FormActions>
            </>
          );
        }}
      />
    </div>
  );
};

PaymentType.propTypes = {
  paymentState: PropTypes.shape({
    paymentInfo: PropTypes.object,
  }).isRequired,
};

export default SelectPaymentAccount;
