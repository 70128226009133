//@flow

// Vendors
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';

// Components
import { FormActions, FormActionsItem, FormError, Modal, Trans } from '../..';
import { PaymentCardFormWrapper } from '../../../Payment';
import { TxButton, TxButtonMode } from 'texkit-ui';
import { TxPaymentCardForm, TxPaymentCardFormMode } from 'texkit-ui/forms';

// Actions
import {
  addPaymentAccount,
  getPaymentAccounts,
} from '../../../../reducers/payment/paymentActions';
import { closeModal } from '../../../../reducers/modal/modalActions';
import { APP_CONSTANTS } from '../../../../lib/data';

// Helpers
import { states } from '../../../../lib/data';
import { paymentFormSchema } from '../../../../lib/validation';

import './add-payment-account-modal.scss';

const serverErrors = {
  MGMW_EX_4000: {
    title: 'WalletBadPayloadTitle',
    id: 'WalletBadPayloadDesc',
    type: 'danger',
  },
  MGMW_EX_4001: {
    title: 'WalletServerErrorTitle',
    id: 'WalletServerErrorDesc',
    type: 'warning',
  },
};

const AddPaymentAccountModal = () => {
  const dispatch = useDispatch();
  const [cardData, setCardData] = useState({});
  const [errorInfo, setErrorInfo] = useState();
  const { selectedLanguage } = useSelector(state => ({
    selectedLanguage: state.language.selected,
  }));

  const handleSubmit = async (values, { setSubmitting }) => {
    const state = states.find(state => state.label === values.state);
    const payload = {
      paymentAccountId: cardData.token,
      cardType: APP_CONSTANTS.CARD_TYPES[cardData.cardtype] || '',
      cardLast4: cardData.token ? cardData.token.slice(-4) : '',
      expirationDate: values.expirationDate.replace('/', ''),
      paymentAccountName: values.name,
      address: {
        streetAddress: values.streetAddress,
        addressLine2: values.streetAddress2 || '',
        city: values.city,
        state: state.value || '',
        postalCode: values.zip,
      },
    };

    try {
      await dispatch(addPaymentAccount(payload));
      dispatch(getPaymentAccounts());
      dispatch(closeModal('AddPaymentAccountModal'));
    } catch (error) {
      const { code } = error;
      if (code) {
        setErrorInfo(serverErrors[code]);
      } else {
        console.error('Payment account error');
      }
    } finally {
      setSubmitting(false);
    }
  };

  const allFieldsTouched = useCallback(touchedMap => {
    const allFields = Object.keys(paymentFormSchema.fields).filter(
      field => !['streetAddress2', 'cvv'].includes(field)
    );
    const touchedFields = Object.keys(touchedMap);
    return allFields.every(field => touchedFields.includes(field));
  }, []);

  return (
    <Modal
      name="AddPaymentAccountModal"
      className="add-payment-account-modal"
      disableOnClickOutside="true"
      closeButtonClassName="modal-close-button"
      onOpen={() => setErrorInfo()}
    >
      <h2 className="h1 modal-title">
        <Trans file="Payment" id="AddCardTitle" />
      </h2>
      <Formik
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {formikProps => {
          const { isSubmitting, isValid, touched } = formikProps;
          const allFieldsAreTouched = allFieldsTouched(touched);

          return (
            <form onSubmit={formikProps.handleSubmit}>
              <PaymentCardFormWrapper formikProps={formikProps}>
                <TxPaymentCardForm
                  displayName="TxPaymentCardForm"
                  paymentMethodTitle={
                    <Trans file="Payment" id="CardInformationTitle" />
                  }
                  billingAddressTitle={
                    <Trans file="Payment" id="BillingAddressTitle" />
                  }
                  mode={TxPaymentCardFormMode.AccountSettingsAdd}
                  onCardFieldMessage={fdmsMessage => setCardData(fdmsMessage)}
                  spanish={selectedLanguage === 'es'}
                />

                <FormError
                  hasError={Boolean(errorInfo)}
                  message={errorInfo}
                  formikProps={formikProps}
                />

                <FormActions>
                  <FormActionsItem>
                    <TxButton
                      mode={TxButtonMode.Primary}
                      type="submit"
                      disabled={
                        !isValid ||
                        !allFieldsAreTouched ||
                        !cardData.token ||
                        isSubmitting
                      }
                    >
                      <Trans file="Payment" id="SavePaymentMethod" />
                    </TxButton>
                  </FormActionsItem>
                </FormActions>
              </PaymentCardFormWrapper>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default AddPaymentAccountModal;
