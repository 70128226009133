import * as yup from 'yup';
import ERRORS from '../errorCopy';
import { commonRules } from '../rules';

const REGEX = {
  STREET_ADDRESS_INVALID_CHARACTERS: /^[A-Za-z0-9#():;?,.&*/+'"\s-]*$/,
  ONLY_VALID_CHARACTERS: /^[a-zA-Z0-9\s,.-]*$/,
  LETTERS_ONLY: /[^a-zA-Z]/g,
  VALID_ZIP: /^[0-9]{5}$/,
  DIGITS: /[0-9]/g,
};

export const capturePhoneSchema = yup.object().shape({
  phoneNum: yup.string().matches(commonRules.REGEX.PHONE_WITH_PARENS, {
    excludeEmptyString: true,
  }),
});

const parseDate = value => {
  const sanitizedValue = value.replace('/', '');
  const month = parseInt(sanitizedValue.slice(0, 2), 10);
  const year = parseInt(sanitizedValue.slice(2), 10);
  return [month, year];
};

export const paymentFormSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required(ERRORS.FIELDS.REQUIRED)
    .test(
      'minTwoChars',
      ERRORS.FIELDS.CREDITCARD.NAME_ON_CARD.MIN,
      (value = '') => {
        const trimmedName = value.replace(REGEX.LETTERS_ONLY, '');
        return trimmedName.length >= 2;
      }
    )
    .test(
      'noDigits',
      ERRORS.FIELDS.CREDITCARD.NAME_ON_CARD.NO_DIGITS,
      (value = '') => {
        const trimmedName = value.replace(REGEX.DIGITS, '');
        return trimmedName.length === value.length;
      }
    )
    .matches(
      REGEX.ONLY_VALID_CHARACTERS,
      ERRORS.FIELDS.CREDITCARD.NAME_ON_CARD.INVALID_CHARACTERS
    ),
  expirationDate: yup
    .string()
    .trim()
    .required(ERRORS.FIELDS.REQUIRED)
    .test(
      'isValidDate',
      ERRORS.FIELDS.CREDITCARD.EXPIRATION.INVALID_FORMAT,
      (value = '') => {
        const [month, year] = parseDate(value);
        const currentYear = new Date().getFullYear();
        const clampedYear = Math.max(year, currentYear);

        if (
          month === undefined ||
          year === undefined ||
          isNaN(month) ||
          isNaN(year) ||
          month < 1 ||
          month > 12 ||
          year < clampedYear
        ) {
          return false;
        }

        const date = new Date(year, month - 1);
        return date.getFullYear() === year && date.getMonth() === month - 1;
      }
    )
    .test(
      'isExpiredDate',
      ERRORS.FIELDS.CREDITCARD.EXPIRATION.FUTURE_DATE,
      (value = '') => {
        const [month, year] = parseDate(value);
        if (month === undefined || year === undefined) {
          return false;
        }

        const expirationDate = new Date(year, month);
        return expirationDate > new Date();
      }
    ),
  cvv: yup
    .string()
    .trim()
    .required(ERRORS.FIELDS.REQUIRED)
    .min(3, ERRORS.FIELDS.CREDITCARD.CVV.INVALID_NON_AMEX)
    .max(4, ERRORS.FIELDS.CREDITCARD.CVV.VALID)
    .test(
      'isValidNonAmex',
      ERRORS.FIELDS.CREDITCARD.CVV.INVALID_NON_AMEX,
      function(value) {
        const { context = {} } = this.options;
        if (!context.cardtype) {
          return true;
        }

        const isAmex = context.cardtype === 'AMEX';
        return isAmex ? true : value.length === 3;
      }
    )
    .test('isValidAmex', ERRORS.FIELDS.CREDITCARD.CVV.INVALID_AMEX, function(
      value
    ) {
      const { context = {} } = this.options;
      if (!context.cardtype) {
        return true;
      }

      const isAmex = context.cardtype === 'AMEX';
      return isAmex ? value.length === 4 : true;
    }),
  streetAddress: yup
    .string()
    .trim()
    .required(ERRORS.FIELDS.REQUIRED)
    .test(
      'minLength',
      ERRORS.FIELDS.ADDRESS.STREET_ADDRESS.MIN,
      (value = '') => {
        const trimmedName = value.replace(REGEX.LETTERS_ONLY, '');
        return trimmedName.length >= 2;
      }
    )
    .matches(
      REGEX.ONLY_VALID_CHARACTERS,
      ERRORS.FIELDS.ADDRESS.STREET_ADDRESS.INVALID_CHARACTERS
    ),
  streetAddress2: yup
    .string()
    .trim()
    .matches(
      REGEX.STREET_ADDRESS_INVALID_CHARACTERS,
      ERRORS.FIELDS.ADDRESS.STREET_ADDRESS_2.INVALID_CHARACTERS_2
    ),
  city: yup
    .string()
    .trim()
    .required(ERRORS.FIELDS.REQUIRED)
    .test('minLength', ERRORS.FIELDS.ADDRESS.CITY.MIN, (value = '') => {
      const trimmedName = value.replace(REGEX.LETTERS_ONLY, '');
      return trimmedName.length >= 2;
    })
    .matches(
      REGEX.ONLY_VALID_CHARACTERS,
      ERRORS.FIELDS.ADDRESS.CITY.INVALID_CHARACTERS
    ),
  state: yup
    .string()
    .trim()
    .required(ERRORS.FIELDS.REQUIRED),
  zip: yup
    .string()
    .trim()
    .required(ERRORS.FIELDS.REQUIRED)
    .matches(
      REGEX.VALID_ZIP,
      ERRORS.FIELDS.ADDRESS.ZIP_FIVE.FIVE_DIGITS_REQUIRED
    ),
});
